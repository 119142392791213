.appBar {
  height: 64px;
}

.footer {
  position: fixed;
  background-color: white;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 0;
  height: 7vw;
  left: 0;
  bottom: 0;
  width: 90vw;
  z-index: 1100;
  
}

.ligne {
  margin-top: 0;
  margin-bottom: 0;
  height: 0.4vw;
  border-width: 0;
  background-color: #1A5D71;
}

.divAccueil {
  
  padding-top: 0.5vw;
  margin-bottom: 10vw;
}

.divBlanc {
  width: 100%;
  height: 3vw;
}

a {
  text-decoration: none;
  color: #FF9300;
}